<template>
    <el-select v-model="selectedTyp" filterable @change="emitChange">
        <el-option label="Sjukfrånvaaro" value="Sjukfrånvaaro" />
        <el-option label="Semester" value="Semester" />
        <el-option label="Vård av barn" value="Vård av barn" />
        <el-option label="Föräldraledig" value="Föräldraledig" />
        <el-option label="Tjänstledig" value="Tjänstledig" />
        <el-option label="10 dagar vid barns födelse" value="10 dagar vid barns födelse" />
        <el-option label="Arbetsskada" value="Arbetsskada" />
        <el-option label="Facklig utbildning" value="Facklig utbildning" />
        <el-option label="Flextid" value="Flextid" />
        <el-option label="Frånvaro övrigt" value="Frånvaro övrigt" />
        <el-option label="Graviditetspenning" value="Graviditetspenning" />
        <el-option label="Kompledig" value="Kompledig" />
        <el-option label="Militärtjänst (max 60 dagar)" value="Militärtjänst (max 60 dagar)" />
        <el-option label="Närståendevård" value="Närståendevård" />
        <el-option label="Permission" value="Permission" />
        <el-option label="Permitterad" value="Permitterad" />
        <el-option label="Sjuk-OB 1" value="Sjuk-OB 1" />
        <el-option label="Sjuk-OB 2" value="Sjuk-OB 2" />
        <el-option label="Sjuk-OB 3" value="Sjuk-OB 3" />
        <el-option label="Sjuk-OB 4" value="Sjuk-OB 4" />
        <el-option label="Sjuk-OB 5" value="Sjuk-OB 5" />
        <el-option label="Smittbärare" value="Smittbärare" />
        <el-option label="Svenska för invandrare" value="Svenska för invandrare" />
    </el-select>
</template>

<script>
export default {
    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
        selectedTypProp: {
            type:    String,
            default: 'Semester',
        },
    },

    data() {
        return {
            selectedTyp: 'Semester',
        };
    },

    watch: {
        selectedTypProp() {
            this.selectedTyp = this.selectedTypProp;
        },
    },

    methods: {
        emitChange() {
            this.$emit('change', this.selectedTyp);
        },
    },
};
</script>